import $ from 'jquery';
import 'what-input';
import './nav.js';
// import{ init } from 'emailjs-com';
// init("user_XTesc4rIfC0LqFev5M4tZ");
import './emailjs.js';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// $(document).foundation();
// (function() {
//     // https://dashboard.emailjs.com/admin/integration
//     emailjs.init('ser_XTesc4rIfC0LqFev5M4tZ');
//  })();
